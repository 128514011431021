import "./Projects.css";

const Projects = () => {

    return (
        <div className="projects">
        <h1>Projects</h1>
        </div>
    );
    }

export default Projects;